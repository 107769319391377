
.text_center{
  text-align: center;
}

.body_tr{
  line-height: 0;
}

.partners {
  height: 183px;
  width: inherit;
}
.partners2 {
  width: inherit;
  height: 145px;
}
.faculty-img {
  width: 100%;
  border: 1px solidwhite;
  border-radius: 10px;
  /* height: fit-content; */
}
.Senate-img {
  width: 100%;
  border: 1px solidwhite;
  border-radius: 10px;
  /* height: fit-content; */
}
.OfficeStaff-img {
  width: 100%;
  border: 1px solidwhite;
  border-radius: 10px;
  /* height: fit-content; */
}
.GoverningBody-img {
  width: 100%;
  border: 1px solidwhite;
  border-radius: 10px;
  /* height: fit-content; */
}
.BnWCommitee-img {
  width: 100%;
  border: 1px solidwhite;
  border-radius: 10px;
  /* height: fit-content; */
}
.FinanceCommitee-img {
  width: 100%;
  border: 1px solidwhite;
  border-radius: 10px;
  /* height: fit-content; */
}

.faculty-link {
  color: #0e407c;
}
.faculty-link:hover {
  color: #1a1c4e;
}
.Senate-link {
  color: #0e407c;
}
.OfficeStaff-link {
  color: #0e407c;
}
.GoverningBody-link {
  color: #0e407c;
}
.BnWCommitee-link {
  color: #0e407c;
}
.FinanceCommitee-link {
  color: #0e407c;
}
.Senate-link:hover {
  color: #1a1c4e;
}
.OfficeStaff-link:hover {
  color: #1a1c4e;
}
.GoverningBody-link:hover {
  color: #1a1c4e;
}
.BnWCommitee-link:hover {
  color: #1a1c4e;
}
.FinanceCommitee-link:hover {
  color: #1a1c4e;
}
hr {
  border-color: #88daf3;
  background-color: #88daf3;

  width: 100%;
  height: 2px;
  justify-content: center;
}

.fees-link {
  color: #0e407c;
}
.fees-link:hover {
  color: #1a1c4e;
}
.theme-color-kota-bg {
  background-color: #0e407c;
  color: white;
}
.theme-color-kota-bg:hover {
  background-color: #1a1c4e;
  color: white;
}
.director-img {
  width: 100%;
}
.director-content {
  color: #0e407c !important;
}
.mycard-default {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.pic-card {
  padding: 0px !important;
}
@media (max-width: 576px) {
  .kota-table {
    overflow-x: auto;
  }
  .ScrollableMenu {
    height: 200px;
    max-height: 200px;
    overflow-y: scroll;
  }
}
.recruiters-img {
  width: 100%;

  padding: 10px;
}

/* .campus_plan_img {
  width: 100%;
  height: 300px;
} */
.scroll-to-top {
  position: fixed;

  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#scrollUp {
  bottom: 70px;
  color: #fff;
  background: #38b6ff;
  display: block;
  font-size: 25px;
  height: 40px;
  line-height: 0;
  position: fixed;
  right: 20px;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.5s cubic-bezier(0, 0, 0, 1) 0s;
  width: 40px;
  border-radius: 50%;
  z-index: 1000;
}
#scrollUp i {
  display: block;
  padding-top: 7px;
}

.carousel-item > img {
  height: 80vh !important;
}

/* fade effect transition css */
/* .carousel-inner .carousel-item {
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  position: absolute;
  transition: opacity 1.5s ease-in-out;
}
.carousel-inner .carousel-item:first-of-type {
  position: relative;
}
.carousel-inner .active {
  opacity: 1;
} */

.navbar-transparent {
  background-color: #fff !important;
}
@media screen and (max-width: 991px) {
  .carousel-item > img {
    margin-top: 35px;
  }
}

@media screen and (max-width: 500px) {
  .carousel-item > img {
    margin-top: -10px;
  }
}
@media screen and (min-width: 992px) {
  .carousel-item > img {
    margin-top: 45px;
  }
}

@media screen and (max-width: 1050px) {
  .carousel-item > img {
    height: 50vh !important;
  }
}

@media screen and (max-width: 500px) {
  .carousel-item > img {
    height: 40vh !important;
  }
}
@media screen and (min-width: 991px) {
  .topbar {
    background-color: #1a1c4e;
    margin-bottom: 0px;
    position: absolute;
    z-index: 999;
  }
  .topbar a {
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }
}
@media screen and (max-width: 990px) {
  .topbar {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

.welcome {
  width: 100%;
  height: 40px;
  background-color: #1a1c4e;
}

@media screen and (max-width: 600px) {
  .smallNews3 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media screen and (max-width: 992px) {
  .smallNews3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media screen and (min-width: 993px) {
  .smallNews3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .smallNews3 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
}
@media screen and (min-width: 700px) {
  .alignAdjust1 {
    padding-left: 22px !important;
  }
}
@media screen and (min-width: 1200px) {
  .alignAdjust1 {
    padding-left: 137px !important;
  }
}
@media screen and (min-width: 700px) {
  .alignAdjust2 {
    padding-left: 20px !important;
  }
}
@media screen and (min-width: 1200px) {
  .alignAdjust2 {
    padding-left: 8rem !important;
  }
}

/* Directors message */

.message-body {
  border: 4px solid #1a1c4e;
}

.directors-img {
  width: 100%;
  max-width: 700px;
}

#dir-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* border: 2px solid black; */
}

@media screen and (min-width: 1350px) {
  #facilities {
    max-height: 400px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1349px) {
  #facilities {
    /* max-height:max-content; */
    overflow-y:visible;
  }
}

.sbilogo-img{
  width: 250px;
}

.dir-col{
  border: 4px solid #f5f5f5;
  height: 50vh;
  overflow: scroll;
}

 .about-col{
  border: 4px solid #1a1c4e;
  height: 50vh;
  overflow: scroll;
} 
.feePayment{
  color: red;
}