.quick_access_card{
    background-color:rgb(23,97,161);
    border-radius:8px;
    width:120px;
    height:120px;
    transition: 0.35s;
}

.quick_access_card:hover {
    background-color:rgb(26,28,78);
    border-radius:8px;
    width:130px;
    height: 130px;
    transition: 0.5s;
}

.card_text{
    color:white;
    text-align:center;
    font-size:15px;
}

.quick_access_title{
    font-size: 2.1875rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
    .quick_access_card{
        background-color:rgb(23,97,161);
        border-radius:8px;
        width:105px;
        height:105px;
        transition: 0.35s;
    }

    .quick_access_card:hover {
        background-color:rgb(26,28,78);
        border-radius:8px;
        width:115px;
        height: 115px;
        transition: 0.5s;
    }
}

@media screen and (max-width: 992px) {
    .quick_access_card{
        background-color:rgb(23,97,161);
        border-radius:8px;
        width:110px;
        height:110px;
    }
    .quick_access_card:hover {
        background-color:rgb(26,28,78);
        border-radius:8px;
        width:120px;
        height:120px;
        transition: 0.5s;
    }
    
    .card_text{
        color:white;
        text-align:center;
        font-size:16px;
    }
}

@media screen and (max-width: 772px) {
    .quick_access_card{
        background-color:rgb(23,97,161);
        border-radius:8px;
        width:100px;
        height:100px;
    }
    .quick_access_card:hover {
        background-color:rgb(26,28,78);
        border-radius:8px;
        width:105px;
        height:105px;
        transition: 0.5s;
    }
    
    .card_text{
        color:white;
        text-align:center;
        font-size:14px;
    }
}

@media screen and (max-width: 576px) {
    .quick_access_card{
        background-color:rgb(23,97,161);
        border-radius:8px;
        width:100px;
        height:100px;
        margin-bottom: 15px; 
    }

    .quick_access_card:hover {
        background-color:rgb(26,28,78);
        border-radius:8px;
        width:110px;
        height:110px;
        transition: 0.5s;
    }
    
    .card_text{
        color:white;
        text-align:center;
        font-size:14px;
    }
}