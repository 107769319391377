html, body {
    margin: 0;
    padding: 0;
  }
  /* .body {
    background: #f0f0f0;
    font-family: 'Roboto', sans-serif;
  } */
  p {
    line-height: 1.35;
  }
  .container1 {
    display: grid;
    grid-gap: 10px;
    grid-template-areas: 
      "aside"
      "main"
    ;
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width: 500px) {
    .container1 {
      grid-template-columns: 30% 1fr;
      grid-template-areas: 
      "aside main"
      ;
      width: 992px;
      /* padding: 0 15px; */
    }
  }
  @media (min-width: 992px) {
    .container1 {
      grid-template-columns: 30% 1fr;
      grid-template-areas: 
        "aside main"
      ;
      width: 1170px;
    }
  }
  .main {
    grid-area: main;
  }
  .aside {
    grid-area: aside;
  }
  .aside .inner, main .inner {
    background: #fff;
    padding: 20px;
  }
  .ul {
    padding: 0;
    list-style: none;
  }
  .h1 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 700;
  }
  .h2 {
    font-size: 20px;
    margin-top: 10px;
    color: #777;
  }
  .h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .h4 {
    font-size: 15px;
    font-weight: 700;
  }
  .section {
    margin-bottom: 0 !important;
  }
  .section .h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;
    margin-top: 0%;
    margin-bottom: 10px;
  }
  a {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  
  .divider {
    padding: 10px 20px 0 20px;
    border-bottom: 2px solid #f0f0f0;
    margin-bottom: 20px;
  }
  .my-profile .fa {
    color: #777;
    margin-right: .8rem;
    top: 4px;
  }
  
  .contact-links li {
    font-size: .9rem;
    margin-bottom: 15px;
  }
  .profileLinks li {
    display: inline-block;
  }
  .profileLinks li a {
    text-decoration: none;
    color: #000;
  }
  .profileLinks li a:hover {
    color: #23527c;
  }
  .skills-list {
    display: flex;
    flex-wrap: wrap;
  }
  .skills-list li {
    font-size: 18px;
    margin: 0 3px 5px;
  }
  .workDates {
    color: #777;
  }
  
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-center {
    text-align: center;
  }
  .center-block {
    display: block;
    margin: 0 auto;
  }
  .small-text {
    font-size: .8rem;
  }
  .img-circle {
    border-radius: 50%;
  }
  .divider {
    padding: 10px 20px 0;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 20px;
  }
  
  .label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
  }
  .label-primary {
    background-color: #222552;
  }
  .sr-only {
    position: absolute !important; /* Outside the DOM flow */
    height: 1px; width: 1px; /* Nearly collapsed */
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
    clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
  }
  
  .text-small{
    font-weight: lighter;
  }

