.hero-event-img {
  height: 250px !important;
}

.event-title-hero {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-image-event:hover img {
  transform: scale(1.2);
}

.card-image-event img {
  transition: transform 0.5s ease;
}

.card-image-event {
  overflow: hidden;
  height: 220px;
}
