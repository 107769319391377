.strip {
    margin-top: 120px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #1a1c4e;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.inside-strip {
    width: 75vw;
    height: 100%;
}

.strip-head {
    padding-top: 50px;
    text-align: center;
    border-bottom: 2px solid #fff;
}

.strip-body {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 70px;
}

.strip-img {
    height: 80px;
    width: 80px;
}

.box1-strip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.75s;
    background-color: rgb(23, 97, 161);
    border-radius: 10px;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box1-strip2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.75s;
    background-color: rgb(23, 97, 161);
    border-radius: 10px;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
}

.box1-strip>h3 {
    color: white;
}

.box1-strip2>h3 {
    color: white;
}

.box-strip-tagline1 {
    font-size: 1.3rem;
    text-align: center;
}

@media screen and (max-width: 1200px) {
    .strip{
        height: 360px;
    }
    .box1-strip {
        height: 120px;
        width: 120px;
    }

    .box1-strip2 {
        height: 150px;
        width: 150px;
    }

    .strip-img {
        height: 80px;
        width: 80px;
    }

    .box-strip-tagline1 {
        font-size: 15px;
    }
}

@media screen and (max-width: 1000px) {
    .strip{
        height: 330px;
    }
    .box1-strip {
        height: 100px;
        width: 100px;
    }

    .strip-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .box1-strip2 {
        height: 120px;
        width: 120px;
    }

    .strip-img {
        height: 60px;
        width: 60px;
    }

    .box-strip-tagline1 {
        font-size: 12px;
    }
}

@media screen and (max-width: 820px) {
    .strip{
        height: 290px;
    }
    .inside-strip {
        width: 80vw;
    }

    .strip-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .box1-strip {
        height: 70px;
        width: 70px;
    }

    .box1-strip2 {
        height: 100px;
        width: 100px;
    }

    .strip-img {
        height: 40px;
        width: 40px;
    }

    .box-strip-tagline1 {
        font-size: 10px;
    }
}

@media screen and (max-width: 600px) {
    .strip-body {
        flex-direction: column;
    }
    .bod-1{
        width: 100% !important;
        padding: 10px;
    }
    .strip{
        height: 470px;
    }
    .inside-strip {
        width: 85vw;
    }

    .box1-strip {
        height: 85px;
        width: 85px;
        /* border-radius: 100%; */
    }

    .box1-strip2 {
        /* border-radius: 100%; */
        height: 85px;
        width: 85px;
    }

    .strip-img {
        height: 55px;
        width: 55px;
    }

    .box1-strip2>h3 {
        font-size: 0.5rem;
    }

    .box1-strip>h3 {
        font-size: 0.5rem;
    }
}

@media screen and (max-width: 315px) {
    .strip{
        height: 530px;
    }
}

.bod-1{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
}