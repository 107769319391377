@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0e407c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a1c4e;
}

.newsli {
  padding: none !important;
}

.counter {
  margin-top: 60px !important;
}
@media only screen and (max-width: 993px) {
  .sectionimg {
    display: none;
  }
}
@media only screen and (max-width: 993px) {
  .tabscontent {
    margin-top: 0px !important;
  }
}
footer {
  background: #1a1c4e;
}

footer .border-light {
  border-color: rgba(183, 176, 156, 0.2) !important;
}

footer .university {
  color: #b7b09c;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-transform: uppercase;
  margin: 1em 0 2em;
}

footer .address {
  font-size: 1.2em;
  /* font-family: "Times New Roman", serif; */
}

footer .address .material-icons {
  color: rgba(255, 255, 255, 0.2);
  font-size: 1em;
  margin-right: 0.3em;
  line-height: 1.4em;
  vertical-align: top;
}

footer .links {
  font-size: 0.87em;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #88daf3;
  font-weight: 500;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  -webkit-padding-start: 0;
}

footer .links li {
  padding-left: 0.4em;
  padding-right: 2em;
  padding-top: 1em;
}

footer .links .material-icons {
  margin: 0 0.2em;
  vertical-align: -0.23em;
  font-size: 1.1em;
  transform: scale(1.5, 1);
}

footer .aside {
  padding-right: 0 !important;
}

footer .aside img {
  width: 70%;
}
footer .aside .lead {
  font-size: 1.5em;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

footer .aside #sponsor {
  margin-left: -0.4em !important;
}

footer .space {
  display: block;
  margin: 0.5em 0;
  content: " ";
}

@media (max-width: 992px) {
  footer .border-left {
    border-top: 0 !important;
    border-left: 0 !important;
  }

  footer .links li {
    min-width: 50%;
  }

  footer .links li:nth-child(even) {
    padding-left: 0.87em;
  }
}

@media (max-width: 576px) {
  footer .container {
    padding: 1em 3em !important;
  }

  footer .links li {
    min-width: 100%;
    padding-left: 0.4em !important;
  }

  footer .address {
    font-size: 1.2em;
  }
}

footer + div {
  background: #0e407c;
  padding: 1em;
  margin: 0;
}

@media (max-width: 576px) {
  footer + div .container {
    padding: 1em 3em !important;
  }
}

@media (max-width: 768px) {
  footer + div .icons {
    padding: 1em 0;
    margin-left: -0.4em;
    text-align: center !important;
  }
}
@media (max-width: 768px) {
  footer + div .footer-copyright {
    text-align: center !important;
  }
}
footer + div .fab {
  font-size: 1.2em;
  margin: 0 0.4em;
}

footer + div .copyright {
  margin: 0em;
  font-size: 1.3em;
  font-family: "Times New Roman", serif;
}

@media (min-width: 992px) {
  footer + div .copyright {
    margin-left: -0.4em !important;
  }
}
footer div div div ul li a {
  color: #88daf3;
}
footer div div div ul li a:hover {
  color: #fff;
}
div div .icons a i {
  color: white;
}
div div .icons a i:hover {
  color: #88daf3;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0e407c !important;
}

.news-color {
  color: #0e407c;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0e407c;
}
.nav-pills .nav-link {
  color: #1a1c4e;
}
.nav-pills .nav-link:hover {
  color: #0e407c;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover {
  color: #fff;
  /* background-color: #0e407c; */
}

.theme-color-kota {
  color: #0e407c;
}
.theme-color-kota-bg {
  background-color: #0e407c;
  color: white;
}
.theme-color-kota-bg:hover {
  background-color: #1a1c4e;
  color: white;
}
.only-padding {
  padding: 0px !important ;
  margin-bottom: 0px !important;
}

/* #navbar-main{
  position: relative;
  top: 28px;
} */

.navbar-brand{
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.navbar{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px ;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navbar-brand-head-kota >*{
  margin: 0px 3px 0px 3px !important;
  font-size: 12px;
  color: #19194d;
  display: flex;
  font-weight: 1000;
  text-transform: none !important;
}

.logo-nav{
  width: 4.5rem;
  height: 4.5rem !important;
  margin: 0px 10px 0px 10px;
}

.nav-cont{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-link-inner--text{
  color: #19194d;
}

.menu-options{
  width: 100% !important;
  margin: 0px 5px 0px 5px !important;
  display: flex;
  justify-content: right;
}

@media screen and (max-width: 1475px) {
.navbar-brand-head-kota > *{
  display: none;
} 

.menu-options{
  justify-content: right;
}

.navbar-brand{
  margin-right: 100px !important;
}
}

@media screen and (max-width: 992px) {
  .navbar-brand{
    margin-right: 0px !important;
  }
  }

@media screen and (max-width: 991px) {
  .navbar-brand-head-kota > * {
    display: flex;
    font-size: 10px;
    word-break: break-all;
  }
  .logo-nav {
    width: 3.5rem;
    height: 3.5rem !important;
  }
  .menu-options{
    justify-content: left;
  }
}

@media screen and (max-width: 500px) {
  .logo-nav {
    width: 2.5rem;
    height: 2.5rem !important;
    margin-left: 0px;
  }

  .navbar-brand-head-kota > * {
    display: flex;
    font-size: 6px;
    overflow-wrap: break-word;
  }
}

@media screen and (max-width: 350px) {
  .logo-nav {
    width: 2rem;
    height: 2rem !important;
    margin-left: 0px;
    margin-right: 5px;
  }

  .navbar-brand-head-kota > * {
    display: flex;
    font-size: 4px;
    overflow-wrap: break-word;
  }

  .navbar-toggler {
    padding: 0px 0px 0px 0px !important;
  }

  #navbar-main {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.navbar-light .navbar-toggler-icon{
  background-image: url(https://img.icons8.com/material-rounded/30/19194d/menu--v1.png) !important;
}
