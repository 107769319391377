/* Form controls */

label {
  font-size: $font-size-sm;
}

.form-control {
  font-size: $input-btn-font-size;
  transition: $transition-base;

  &:focus {
    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }

  &.is-valid,
  &.is-invalid {
    background-image: none;
  }
}

/* Textareas */

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

/* Alternative styles */

.form-control-muted {
  background-color: $input-muted-bg;
  border-color: $input-muted-bg;
  box-shadow: none;

  &:focus {
    background-color: $input-muted-bg;
  }
}

.form-control-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow 0.15s ease;

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

// Sizes

.form-control-lg {
  font-size: $font-size-base;
}

// Validation

.has-success,
.has-danger {
  position: relative;

  &:after {
    width: 19px;
    height: 19px;
    line-height: 19px;
    text-align: center;
    font-family: "NucleoIcons";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 2px;
    transform: translateY(50%);
    border-radius: 50%;
    font-size: 9px;
    opacity: 1;
  }

  .form-control-alternative {
    &.is-valid,
    &.is-invalid {
      &:focus {
        box-shadow: $input-focus-alternative-box-shadow !important;
      }
    }
  }
}

.has-success {
  &:after {
    content: "\ea26";
    color: daken($form-feedback-valid-color, 18%);
    background-color: $form-feedback-valid-bg;
  }
  .form-control {
    background-color: $input-focus-bg;

    &:focus {
      border-color: $input-focus-border-color;
    }

    // Placeholder
    &::placeholder {
      color: $form-feedback-valid-color;
    }
  }
}

.has-danger {
  &:after {
    content: "\ea53";
    color: daken($form-feedback-invalid-color, 18%);
    background-color: $form-feedback-invalid-bg;
  }
  .form-control {
    background-color: $input-focus-bg;

    &:focus {
      border-color: $input-focus-border-color;
    }

    // Placeholder
    &::placeholder {
      color: $form-feedback-invalid-color;
    }
  }
}
